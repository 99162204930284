// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-ballot-buzz-js": () => import("./../../../src/pages/ballot-buzz.js" /* webpackChunkName: "component---src-pages-ballot-buzz-js" */),
  "component---src-pages-execs-js": () => import("./../../../src/pages/execs.js" /* webpackChunkName: "component---src-pages-execs-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-scimun-js": () => import("./../../../src/pages/scimun.js" /* webpackChunkName: "component---src-pages-scimun-js" */),
  "component---src-pages-tedxtams-js": () => import("./../../../src/pages/tedxtams.js" /* webpackChunkName: "component---src-pages-tedxtams-js" */),
  "component---src-templates-buzz-post-js": () => import("./../../../src/templates/buzz-post.js" /* webpackChunkName: "component---src-templates-buzz-post-js" */)
}

